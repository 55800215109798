.sign-in-left-register {
  width: 35%;
  display: inline;
  height: 80vh;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: relative;
}

.sign-in-left-login {
  width: 49.9%;
  display: inline;
  height: 80vh;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  position: relative;
}

.sign-in-right {
  width: 49.9%;
  display: inline-block;
  height: 100%;
  background: #b9c0fd1a 0% 0% no-repeat padding-box;
  opacity: 1;
}

.sign-in-left-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
}

.sign-in-left-head-1,
.sign-in-left-head-2 {
  font: normal normal 900 100px/120px avenir-black;
  letter-spacing: 0px;
  color: #5861ae;
  text-transform: capitalize;
  opacity: 1;
}

.sign-in-left-head-1 {
  margin-top: 50px;
}

.sign-in-left-desc {
  font: normal normal normal 20px/26px avenir-book;
  letter-spacing: 0.8px;
  color: #565657;
  text-transform: capitalize;
  opacity: 1;
}

.not-having-account {
  font: normal normal normal 16px/26px avenir-book;
  letter-spacing: 0.64px;
  color: #565657;
  text-transform: capitalize;
  opacity: 1;
}

.register-btn {
  width: 150px;
  height: 50px;
  border: 2px solid #4552c1;
  border-radius: 4px;
  opacity: 1;
  cursor: pointer;
}

.register-btn:hover {
  background-color: #4552c1;
  transition: 0.3s ease;
}

.register-btn span {
  display: inline-block;
  margin-left: 47.14px;
  margin-top: 10px;
  font: normal normal bold 15px/20px openSans-regular;
  letter-spacing: 0.3px;
  color: #4552c1;
  opacity: 1;
}

.register-btn:hover span {
  color: #ffffff;
  transition: 0.3s ease;
}

.sign-in-left-line {
  width: 1px;
  background-color: #5861ae;
  height: 150px;
  position: absolute;
  right: 0;
}
