.orders {
  display: flex;
  width: 96%;
  background-color: white;
  padding: 30px;
  position: relative;
  overflow-y: auto;
  height: 87vh;
}

.orders-confirm {
  width: 20rem;
  height: 12rem;
  background-color: red;
  position: absolute;
  left: calc(50% - 10rem);
  top: 15%;
}

.orderspage {
  display: flex;
  flex-direction: column;
}

table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

thead {
  background-color: #383737;
  color: white;
  display: flex;
  width: 100%;
  height: 7%;
  align-items: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 15px;
}

thead > * {
  width: 10%;
}

tbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  text-align: center;
}

tr > * {
  width: 10.7%;
  height: 10%;
  font-size: 15px;
}

td > * {
  width: 100%;
}

td {
  font-family: sans-serif;
}

tr {
  height: 50px;
  border-bottom: 1px solid #eaedf0;
  border-left: 1px solid #eaedf0;
  border-right: 1px solid #eaedf0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.price {
  color: #5861ae;
}

.top {
  display: flex;
  margin-bottom: 3%;
  justify-content: space-between;
  width: 100%;
}
