.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 100;
}

.popup-inner {
  width: 100%;
  height: 100%;
  max-width: 900px;
  background-color: #fff;
  position: relative;
}

.popup-header {
  width: 100%;
  height: 50px;
  /* UI Properties */
  background: #5861ae 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header-h3 {
  margin-left: 20px;
  width: 102px;
  height: 30px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 22px/20px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.popup-header-close-btn {
  cursor: pointer;
  margin-right: 20px;
  color: #ffffff;
  font-size: 20px;
}

/* Sub header */

.popup-subheader {
  width: 100%;
  height: 60px;
  /* UI Properties */
  background: #f4f9ff 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}

.popup-subheader-store-address {
  margin-right: 100px;
  margin-left: 30px;
  width: 204px;
  height: 27px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 16px/27px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.56px;
  color: #3a3a3b;
  opacity: 0.3;
  border-top: none;
  border-left: none;
  border-right: none;
}

.popup-subheader-store-address-black {
  margin-right: 100px;
  margin-left: 30px;
  width: 204px;
  height: 27px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 16px/27px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.56px;
  color: black;
  opacity: 0.85;
  border-top: none;
  border-left: none;
  border-right: none;
}

.popup-subheader-store-address-details {
  margin-right: 180px;
}

.popup-subheader-store-address-details-p1 {
  width: 121px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 16px/22px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.38px;
  color: #1b2734;
  opacity: 1;
}

.popup-subheader-store-address-details-p2 {
  letter-spacing: -2px;
  text-align: left;
  font: normal normal normal 16px/22px openSans-regular;
  color: #1b2734;
  opacity: 1;
  margin-top: -10px;
}

.popup-subheader-store-phone-p1 {
  margin-right: 300px;
  text-align: left;
  font: normal normal 600 16px/22px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.38px;
  color: #1b2734;
  opacity: 1;
}

.popup-subheader-store-phone-p2 {
  letter-spacing: -2px;
  text-align: left;
  font: normal normal normal 16px/22px openSans-regular;
  color: #1b2734;
  opacity: 1;
  margin-top: -10px;
}

/* Total */

.popup-total {
  width: 848px;
  height: 49px;
  /* UI Properties */
  background: #5861ae 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.popup-total-p1 {
  width: 52px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 15px/24px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.43px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
}

.popup-total-p2 {
  width: 71px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 17px/30px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.53px;
  color: #ffffff;
  opacity: 1;
}

/* Address */

.popup-address p {
  width: 57px;
  height: 19px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 14px/19px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.42px;
  color: #3b3737;
  opacity: 1;
  margin-left: 25px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.popup-address-details {
  display: flex;
  align-items: center;
  margin-left: 25px;
}

.popup-address-details-1,
.popup-address-details-2 {
  width: 236px;
  height: 85px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #5861ae;
  border-radius: 4px;
  opacity: 1;
  margin-right: 25px;
}

.popup-address-details-add {
  width: 90px;
  height: 24px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 18px/24px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.18px;
  color: #5861ae;
  opacity: 1;
}

.popup-address-details-1-p1 {
  width: 54px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 18px/24px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.18px;
  color: #3e3f40;
  opacity: 1;
  margin: 8px;
}

.popup-address-details-1-p2 {
  width: 198px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 16px/22px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.16px;
  color: #3e3f40;
  opacity: 1;
  margin-left: 8px;
  margin-top: 6px;
}

/* Footer */
.popup-footer {
  width: 926px;
  height: 70px;
  /* UI Properties */
  background: #f4f4f4 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 6px #00000015;
  opacity: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 6.5%;
  position: absolute;
  bottom: 0;
}

.popup-footer-btn {
  width: 150px;
  height: 42px;
  /* UI Properties */
  background: #5861ae 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
  color: #ffffff;
  margin-right: 40px;
}

/* Main-Body Table */

.popup-table-body-p {
  width: 57px;
  height: 0px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 14px/19px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.42px;
  color: #3b3737;
  opacity: 1;
  margin-left: 25px;
}

.popup-table-body-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 8%px;
  border-bottom: 1px solid #e0e0e0;
  width: 90%;
  margin-left: 40px;
}

.popup-table-body-content-p1 {
  width: 55px;
  height: 24px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 18px/24px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.43px;
  color: #1b2734;
  opacity: 1;
}

.popup-table-body-content-p2 {
  width: 119px;
  height: 22px;
  /* UI Properties */
  text-align: left;
  font: italic normal normal 16px/22px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.38px;
  color: #1b2734;
  opacity: 1;
}

.popup-table-body-content-p3 {
  width: 65px;
  height: 22px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 16px/22px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.38px;
  color: #1b2734;
  opacity: 1;
}

.popup-table-body-content-p4 {
  width: 35px;
  height: 27px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 20px/27px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.48px;
  color: #5861ae;
  opacity: 1;
}

.popup-subtotal,
.popup-charges {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  margin-right: 70px;
}

.popup-subtotal-p1 {
  width: 74px;
  height: 22px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 16px/22px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.38px;
  color: #1b2734;
  opacity: 1;
}

.popup-charges-p1 {
  width: 124px;
  height: 22px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 16px/22px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.38px;
  color: #1b2734;
  opacity: 1;
}

.popup-subtotal-p2,
.popup-charges-p2 {
  width: 32px;
  height: 24px;
  /* UI Properties */
  text-align: left;
  font: normal normal 600 18px/24px openSans-regular;
  font-family: sans-serif;
  letter-spacing: 0.43px;
  color: #272727;
  opacity: 1;
}

img.address-icon {
  position: relative;
  top: -77px;
  left: 197px;
  width: 22px;
  height: 22px;
}
