/* .alertpage {
  display: flex;
  width: 196vh;
  height: 100vh;
  position: absolute;
  background-color: white;
} */

.alert-container {
  margin: auto;
  width: 415px;
  height: 200px;
  border-radius: 2px 2px 1px 1px;
  position: absolute;
  background-color: white;
  left: calc(50% - 207.5px);
  top: 7rem;
  -webkit-box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.82);
  box-shadow: 0px 0px 11px 2px rgba(0, 0, 0, 0.82);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  color: white;
  background-color: #5861ae;
  justify-content: space-between;
  padding: 0 1rem;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.proceed {
  width: 107px;
  height: 32px;
  color: white;
  background-color: #5861ae;
  border-radius: 4px;
  margin-top: 20px;
  border: 2px solid #5861ae;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: large;
}

.proceed:hover {
  background-color: #fff;
  color: #5861ae;
  transition: 0.3s ease;
}

.confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: larger;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
span.number {
  color: #5861ae;
}
