/* .ordersucess {
  display: flex;
  width: 196vh;
  height: 100vh;
  background-color: red;
  position: absolute;
} */
.popup2 {
  text-align: center;
  border: 1px solid #e5e0e0;
  border-radius: 5px;
  height: 504px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 466px;
  background-color: white;
  position: absolute;
  left: calc(50% - 233px);
  top: 6rem;
  padding: 0 5rem;
  justify-content: space-evenly;
}

.goto-orders {
  width: 235px;
  height: 44px;
  border: none;
  border-radius: 22px;
  color: white;
  background-color: #5861ae;
  margin-top: 70px;
  font-size: 17px;
  font-weight: bold;
}

.para {
  font-size: 1.5rem;
}

.orderbutton {
  margin-top: -3.5rem;
}

.orderbutton > a > button {
  cursor: pointer;
}
