/* styling header */
.homenavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 48px #0000000f;
  z-index: 1;
  position: relative;
}

.home-headname {
  margin-left: 72px;
  font: normal normal 900 20px/27px avenir-book;
  letter-spacing: 1.8px;
  color: #5861ae;
  text-transform: uppercase;
  opacity: 1;
}

.homenav-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.homenav-wrapper > div {
  height: 100%;
  width: 115px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 14px/19px avenir-book;
  letter-spacing: 1.05px;
  color: #565657;
  border-left: 1px solid #e3e3e3;
}

.homenav-wrapper .username {
  background: #5861ae 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  color: #ffffff;
  width: 55%;
}

.dropbtn {
  background-color: #5861ae;
  color: white;
  font-size: 16px;
  border: none;
  width: 143px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.username {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 78px;
  width: 143px;
}

.username:hover .dropdown-content {
  display: block;
  color: #5861ae;
  background-color: #e3e3e3;
}

.logout-button {
  top: 88px;
  color: #ffffff;
  background-color: #5861ae;
  width: 100%;
  height: 50px;
  text-align: center;
  border: none;
  cursor: pointer;
  font-size: medium;
}
.logout-button:hover {
  color: #5861ae;
  background-color: white;
  border: 1px solid #5861ae;
  transition: 0.3s ease;
}
